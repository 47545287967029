/**
 * @tips - Try to use RGB colors instead of Hexa
 * then, just put 50% in BG_PRIMARY_GRADIENT
 */
export const BG_PRIMARY = '#491625';
export const BG_PRIMARY_GRADIENT = 'rgb(44 46 53 / 70%)';
export const BG_SECONDARY = 'rgb(203 190 171)';
export const BG_ALTERNATIVE = 'rgb(67 21 34)';
export const BG_ALTERNATIVE_DARK = '#3B1410';
// color text
export const TEXT_PRIMARY = '#CBBEAB';
export const TEXT_PRIMARY_LIGHT = '#F5F6E8';
export const TEXT_SECONDARY = '#491625';
export const TEXT_ALTERNATIVE = '#3B1410';
export const TEXT_SHADOW = '#3c1410';
// music & navigation
export const NAVIGATION_COLOR = '#dcd0b2';
export const DRAWER_COLOR = 'rgb(116 84 63)';

// DEFAULT BUTTON PROPS
export const BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};

export const DEFAULT_BUTTON_PROPS = {}